.spin_wrapp{
    width: 100vw;
    height: 100vh;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    position: relative;
}

@media only screen and (max-width: 425px) {
  
    .spin_wrapp > img{
      width: 120px;
      object-fit: cover;
    }
  }